import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-h6" }
const _hoisted_2 = { class: "q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvaliacaoForm = _resolveComponent("AvaliacaoForm")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_video = _resolveComponent("q-video")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.store.getters.userPerfil != 'professor')
      ? (_openBlock(), _createBlock(_component_AvaliacaoForm, {
          key: 0,
          onUpdate: _ctx.list,
          avaliacoes: _ctx.rows,
          ref: "modalForm"
        }, null, 8, ["onUpdate", "avaliacoes"]))
      : _createCommentVNode("", true),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.avaliacaoShow,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.avaliacaoShow) = $event)),
      "full-width": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, " Reolução da Avaliação: " + _toDisplayString(_ctx.avaliacao?.nome), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avaliacao?.correcao, (correcao, index) => {
              return (_openBlock(), _createBlock(_component_q_card_section, {
                class: "q-pt-none",
                key: index
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_video, {
                    ratio: 16 / 9,
                    src: correcao
                  }, null, 8, ["src"])
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_q_btn, {
                  flat: "",
                  label: "OK",
                  color: "primary"
                }, null, 512), [
                  [_directive_close_popup]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_table, {
        title: "Avaliações",
        "row-key": "id",
        rows: _ctx.rows,
        columns: _ctx.columns,
        filter: _ctx.filter,
        loading: _ctx.loading,
        pagination: { rowsPerPage: 10 }
      }, {
        top: _withCtx(() => [
          _createVNode(_component_q_space),
          _createVNode(_component_q_input, {
            dense: "",
            debounce: "300",
            color: "primary",
            modelValue: _ctx.filter,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter) = $event))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "search" })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (_ctx.store.getters.userPerfil != 'professor')
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    "no-caps": "",
                    flat: "",
                    dense: "",
                    color: "primary",
                    "icon-right": "edit",
                    onClick: ($event: any) => (_ctx.openModalForm(props.row))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Editar ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.store.getters.userPerfil != 'professor')
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 1,
                    "no-caps": "",
                    flat: "",
                    dense: "",
                    color: "green",
                    "icon-right": "post_add",
                    to: '/enviar-nota/' + props.row.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Enviar Nota ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                type: "a",
                color: "green",
                "icon-right": "visibility",
                href: props.row.url,
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Visualizar Prova ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["href"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "green",
                "icon-right": "check_box",
                onClick: ($event: any) => (_ctx.verResolucao(props.row))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Ver Resoluções ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["onClick"]),
              (_ctx.store.getters.userPerfil != 'professor')
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 2,
                    "no-caps": "",
                    flat: "",
                    dense: "",
                    color: "primary",
                    "icon-right": "link",
                    onClick: ($event: any) => (_ctx.copyLink(props.row))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Copiar URL da Avaliação na Plataforma ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "filter", "loading"])
    ])
  ]))
}